import Vue from "vue";
import VueRouter, { Route, NavigationGuardNext } from "vue-router";
import { Store } from "@/store/types";

Vue.use(VueRouter);

import store from "@/store";

import Main from "@/views/Main.vue";
import Login from "@/views/Login.vue";
import DeployerForm from "@/views/DeployerForm.vue";
import InstanceManager from "@/views/InstanceManager.vue";
import DCUserAdmin from "@/views/DCUserAdmin.vue";
import Example1 from "@/views/Example1.vue";
import Example2 from "@/views/Example2.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Main,
    redirect: { name: "deployer" },
    children: [
      {
        path: "/deployer",
        name: "deployer",
        component: DeployerForm,
      },
      {
        path: "/instances",
        name: "instances",
        component: InstanceManager,
      },
      {
        path: "/dcusers",
        name: "dcusers",
        component: DCUserAdmin,
      },
      {
        path: "/example2",
        name: "example2",
        component: Example2,
      },
    ],
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/logout",
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      (store as Store).dispatch("authentication/logout");
      next("/");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next) => {
  const publicPages = ["/login"];
  const isNotPublic = !publicPages.includes(to.path);
  const isAuthenticated = (store as Store).state.authentication.isAuthenticated;
  if (isNotPublic && !isAuthenticated) {
    return next("/login");
  }

  if (to.path === "/login" && isAuthenticated) {
    return next("/");
  }

  return next();
});

export default router;
