<template>
    <div>
        <br><br>
        <!-- <es-select v-model="account" :options="accounts" label="Account" />
        <es-select v-model="region" :options="regions" label="Region" /> -->

        <!-- <h5>Account</h5>
        <Dropdown v-model="selectedAccount" :options="accounts" 
                  optionLabel="label" optionValue="value"
                  placeholder="Select an Account" /> -->

        <Toolbar class="mb-4">
            <template #start>
            </template>
            <template #end>
                <es-button-next theme="classic" v-on:click="addUser">
                    Add
                </es-button-next>
                <es-button-next theme="classic" v-on:click="getUsers">
                    List
                </es-button-next>
                <es-button-next theme="classic" v-on:click="deleteSelectedUser">
                    Delete
                </es-button-next>
            </template>
        </Toolbar>
        <h5>&nbsp;</h5>
        <!-- https://www.primefaces.org/primevue-v2/#/datatable/selection -->
        <DataTable ref="dt" :value="users" :selection.sync="selectedUser" dataKey="Name" 
            class="p-datatable-striped p-datatable-gridlines" 
            :resizableColumns="true" autoLayout
            :filters="filters" showGridlines
        >
            <template #header>
                <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                    <h3 class="mb-2 md:m-0 p-as-md-center">Manage DC Users</h3>
                </div>
                <h5>&nbsp;</h5>
            </template>

            <Column selectionMode="single" style="width: 3rem" :exportable="false"></Column>
            <Column field="GivenName" header="First Name" :sortable="true" style="min-width:12rem"></Column>
            <Column field="Surname" header="Last Name" :sortable="true" style="min-width:12rem"></Column>
            <Column field="Name" header="Name" :sortable="true" style="min-width:12rem"></Column>
            <!-- <Column field="user_id" header="User Id" :sortable="true" style="min-width:12rem"></Column> -->
        </DataTable>

	</div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { FilterMatchMode } from 'primevue/api';
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import Column from "primevue/column";
import Toolbar from 'primevue/toolbar';
import Dropdown from 'primevue/dropdown';
import EsButtonNext from "@EditShareCo/es-button-next";
import EsSelect from "@EditShareCo/es-select";
import myfile from "@/shared/myfile";

const API_URL = "https://2e5qvofuq7mj4nfbwjtjvlozh40pizik.lambda-url.us-east-2.on.aws/" //process.env.VUE_APP_API_URL

export default Vue.extend({
    name: "DCUserAdmin",
    components: {
        DataTable,
        Button,
        InputText,
        Column,
        Toolbar,
        Dropdown,
        EsButtonNext,
        EsSelect,
    },
    data() {
        return {
            users: [],
            user: "",
            selectedUser: null,
            filters: {},
        }
    },
    created() {
        this.initFilters();
        // myfile.bar()
    },
    mounted: function () {
        this.getUsers();
    },
    methods: {
        getUsers() {
            let user_ids = [];

            let payload = {};
            payload["target"] = "domain_controller";
            payload["command"] = "list_users";
            payload["payload"] = {};
            console.log(payload);
            const path = API_URL;
            axios
                .post(path, payload, {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res.status);
                    console.log(res.data);
                    this.users = res.data["data"];
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });

        },
        deleteSelectedUser() {
            let user_ids = [];
            for (var i = 0; i < this.selectedUsers.length; i++) { 
                let inst = this.selectedUsers[i]
                console.log("deleting user: " + inst["Name"]);
                user_ids.push(inst["Name"]);
            }
            this.selectedUsers = null;

            let payload = {};
            payload["target"] = "domain_controller";
            payload["command"] = "delete_user";
            payload["payload"] = {"account": "foo.bar"}; // should come from selected user
            console.log(payload)
            const path = API_URL;
            axios
                .post(path, payload, {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res.status);
                    console.log(res.data);
                    this.getUsers();
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });

        },
        updatedSelectedUser() {
            let user_ids = [];
            for (var i = 0; i < this.selectedUsers.length; i++) { 
                let inst = this.selectedUsers[i]
                console.log("deleting user: " + inst["Name"]);
                user_ids.push(inst["Name"]);
            }
            this.selectedUsers = null;

            let payload = {};
            payload["target"] = "domain_controller";
            payload["command"] = "delete_user";
            payload["payload"] = {"account": "foo.bar"}; // should come from selected user
            console.log(payload)
            const path = API_URL;
            axios
                .post(path, payload, {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res.status);
                    console.log(res.data);
                    this.getUsers();
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });

        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
    }
})
</script>

<style lang="scss" scoped>

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

.isstopped {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
}

.isother {
    font-weight: 700;
    color: #FFA726;
}

.isrunning {
    font-weight: 700;
    color: #66BB6A;
}

::v-deep .p-datatable-header {
  background-color: transparent !important;
  border-width: 0 0 0 0 !important;
//   display: flex; // this smushes the Search box to the left, not what we want
}

::v-deep .table-header-class,
::v-deep .p-treetable-tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.01) !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr {
  background-color: rgba(40, 44, 48, 0.3);
//   border: 1px solid #FFF !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
}

</style>
